<template>
  <div class="messages">
    <div class="message">
      <img
        alt="Быстрые платежи"
        src="@/assets/icons/profile/payment-message-timer.svg"
      />
      <div class="title">Быстрые платежи</div>
      <div class="description">
        Пополняем за 5 секунд <br />
        выводим в течение 24 часов.
      </div>
    </div>

    <div class="message">
      <img
        alt="Служба поддержки"
        src="@/assets/icons/profile/payment-message-support.svg"
      />
      <div class="title">Служба поддержки</div>
      <div class="description">
        Вслучае возникновения проблем <br />напишите в нашу
        <a :href="link" target="_blank">службу поддержки.</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      link: process.env.VUE_APP_SUPPORT_LINK || "https://google.com",
    };
  },
};
</script>

<style lang="scss" scoped>
.messages {
  display: flex;
  .message {
    width: 50%;
    text-align: center;
    .title {
      font-size: 16px;
      margin-bottom: 15px;
      font-weight: 500;
    }
    img {
      margin-bottom: 10px;
    }
    .description {
      font-size: 15px;
      color: #a1a6bb;
      a {
        color: #0085ff;
      }
    }
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
    .message {
      width: 100%;
      margin-bottom: 25px;
    }
  }
}
</style>
