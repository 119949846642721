<template>
  <div class="badges">
    <div
      class="badge"
      v-for="badge in badges"
      :key="badge"
      :class="{ active: badge === amount }"
      @click="$emit('setActive', badge)"
    >
      <span>
        <img
          alt="active"
          src="@/assets/icons/profile/ic-actions-check-simple.svg"
        />
      </span>
      {{ badge }} ₽
    </div>
  </div>
</template>

<script>
export default {
  props: {
    amount: Number,
  },
  data() {
    return {
      badges: [100, 250, 500, 1000, 2000, 5000, 10000, 15000],
    };
  },
};
</script>

<style lang="scss" scoped>
.badges {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .badge {
    width: 190px;
    @media (max-width: 768px) {
      width: calc(100% / 2 - 10px);
    }
    height: 50px;
    margin-bottom: 10px;
    display: flex;
    color: #abb6ce;
    align-items: center;
    position: relative;
    justify-content: center;
    font-weight: 500;
    font-size: 18px;
    border: 1px solid #f5f6fa;
    border-radius: 6px;
    span {
      display: none;
    }
    transition: 200ms ease;
    &:hover {
      color: black;
      cursor: pointer;
    }
    &.active {
      border: 2px solid #0085ff;
      color: black;
      pointer-events: none;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        background-color: #0085ff;
        border-radius: 0 0 6px;
        width: 23px;
        height: 19px;
      }
    }
  }
}
</style>
