<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div v-click-outside="onClickOutside" class="container">
        <img
          alt="close"
          class="close"
          src="@/assets/icons/close-modal.svg"
          @click="onClickOutside"
        />

        <div class="title">
          <div class="badge">
            <img alt="wallet-white" src="@/assets/icons/wallet-500.svg" />
          </div>
          Пополнение счёта
        </div>

        <div class="form-group">
          <label>КОШЕЛЕК</label>
          <div class="group">
            <span>{{ transfer.person }}</span>
            <img
              alt="copy"
              src="@/assets/icons/copy.svg"
              @click="copy(transfer.person)"
            />
          </div>
        </div>

        <div class="form-group">
          <label>СУММА (В РУБЛЯХ)</label>
          <div class="group">
            <span>{{ transfer.amount }}</span>
            <img
              alt="copy"
              src="@/assets/icons/copy.svg"
              @click="copy(transfer.amount)"
            />
          </div>
        </div>

        <div class="form-group">
          <label>КОМЕНТАРИЙ</label>
          <div class="group">
            <span>{{ transfer.comment }}</span>
            <img
              alt="copy"
              src="@/assets/icons/copy.svg"
              @click="copy(transfer.comment)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";

export default {
  props: {
    transfer: Object,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    onClickOutside() {
      this.$emit("close");
    },
    copy(text) {
      navigator.clipboard.writeText(text);
      this.$toast.success("Успешно скопировано");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    .container {
      width: 540px;
      // height: 500px;
      margin: 0px auto;
      background-color: #f5f6fa;
      border-radius: 34px;
      // display: flex;
      padding: 40px;
      position: relative;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      img.close {
        position: absolute;
        right: 40px;
        top: 60px;
        transition: 200ms ease;
        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }

      .title {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 35px;
        .badge {
          margin-right: 15px;
          width: 60px;
          height: 60px;
          background: #0085ff;
          border-radius: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .form-group {
        border: 1px solid #ebeaf5;
        border-radius: 7px;
        position: relative;
        padding: 15px;
        margin-bottom: 35px;
        &:last-child {
          margin-bottom: 10px;
        }
        label {
          position: absolute;
          background-color: #f5f6fa;
          padding: 0 10px;
          color: #918ea9;
          font-size: 14px;
          font-weight: 500;
          top: -10px;
          left: 10px;
        }
        .group {
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            font-weight: 500;
            font-size: 20px;
          }
          img:hover {
            cursor: pointer;
            opacity: 0.8;
          }
        }
      }
    }
  }
}
</style>
