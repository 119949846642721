<template>
  <div class="methods">
    <transition-group name="fade" tag="div">
      <div
        class="method"
        v-for="(item, methodId) in meta"
        :key="item.name"
        :class="{ active: item.name === method.name }"
        @click="$emit('setMethod', methodId)"
      >
        <img
          v-if="item.name === 'qiwi'"
          :alt="item.name"
          :class="item.name"
          src="@/assets/icons/profile/qiwi.png"
        />
        <img
          v-else-if="item.name === 'piastrix' || item.name === 'ps_wallet'"
          :alt="item.name"
          :class="item.name"
          src="@/assets/icons/profile/piastrix.png"
        />
        <img
          v-else-if="item.name === 'freekassa' || item.name === 'fk_wallet'"
          :alt="item.name"
          :class="item.name"
          src="@/assets/icons/profile/fkwallet.png"
        />
        <img
          v-else-if="item.name === 'yandex'"
          :alt="item.name"
          :class="item.name"
          src="@/assets/icons/yoomoney.png"
        />
        <img
          v-else-if="item.name === 'card'"
          :alt="item.name"
          :class="item.name"
          src="@/assets/icons/card.png"
        />

        {{ item.name | translateName }}
        <span class="com">{{ item.commission || 0 }}%</span>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  props: {
    meta: Array,
    method: Object,
  },
  filters: {
    translateName(value) {
      const names = {
        fk_wallet: "FKWallet",
        ps_wallet: "Piastrix",
        card: "Visa / Mastercard / Мир",
        yandex: "ЮMoney / Яндекс.Деньги",
      };
      return names[value] || value;
    },
  },
};
</script>

<style lang="scss" scoped>
.methods {
  margin-right: 15px;
  //   padding-right: 15px;

  @media (max-width: 768px) {
    margin-right: 0;
  }

  .method {
    border: 2px solid #f5f6fa;
    margin-bottom: 8px;
    font-weight: 500;
    height: 50px;
    display: flex;
    align-items: center;
    // padding-right: 10px;
    border-radius: 6px;
    position: relative;
    text-transform: capitalize;
    transition: 200ms ease;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
      border-color: #0085ff;
    }
    &.active {
      border-color: #0085ff;
      pointer-events: none;
    }
    img {
      width: 50px;
      height: 30px;
      &.freekassa,
      &.yandex,
      &.card,
      &.fk_wallet {
        height: 20px;
      }
      object-fit: contain;
    }

    span.com {
      position: absolute;
      background: #f5f6fa;
      border-radius: 4px;
      color: #bdc1d3;
      font-size: 14px;
      font-weight: 500;
      right: 10px;
      padding: 5px 8px;
    }
  }
}
</style>
