<template>
  <div class="deposits">
    <div class="left">
      <div class="title">Способ оплаты</div>
      <Methods :meta="meta" :method="method" @setMethod="changeMethod" />
    </div>

    <div class="right">
      <Alert />

      <CurrentMethod :method="method" />

      <LineDown />

      <div class="title">Выберите сумму пополнения</div>
      <Badges :amount="Number(amount)" @setActive="setAmount" />

      <LineDown />

      <form class="inputs" @submit.prevent="deposit">
        <div class="flex">
          <div class="group">
            <label>Сумма пополнения</label>
            <div class="input">
              <img
                alt="amount"
                src="@/assets/icons/profile/payment-coins.svg"
              />
              <input type="number" required v-model="amount" />
            </div>
          </div>

          <div class="group">
            <label>Комиссия</label>
            <div class="input">
              <img
                alt="amount"
                src="@/assets/icons/profile/payment-coins.svg"
              />
              <input
                type="text"
                disabled
                :value="(amount * (method.commission / 100)).toFixed(2)"
              />
            </div>
          </div>
        </div>

        <button>ПЕРЕЙТИ К ОПЛАТЕ</button>
      </form>

      <Modal v-if="modalShow" :transfer="transfer" @close="modalShow = false" />

      <Messages />
    </div>
  </div>
</template>

<script>
import Messages from "@/containers/Payments/Messages";
import LineDown from "@/components/Payments/Line";
import Methods from "@/containers/Payments/Methods";
import Alert from "@/components/Payments/Alert";
import CurrentMethod from "@/components/Payments/CurrentMethod";
import Badges from "@/containers/Payments/Badges";
import Modal from "@/components/Payments/Modal";
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      meta: [],
      modalShow: false,
      transfer: {},
      method: {
        max: 1500000,
        min: 1000,
        name: "qiwi",
      },
      amount: 100,
    };
  },
  components: {
    Messages,
    LineDown,
    Methods,
    Alert,
    CurrentMethod,
    Badges,
    Modal,
  },
  computed: {
    ...mapGetters("user", ["convertAmount", "getBalance"]),
    ...mapState("user", ["user"]),
  },
  async created() {
    try {
      const { data } = await this.$http.get("wallet/deposit/meta");
      this.meta = data.response;
      this.method = this.meta[0];
    } catch (error) {
      this.$toast.error(error?.message || "Ошибка отправки запроса");
    }
  },
  methods: {
    changeMethod(id) {
      this.method = this.meta[id];
    },
    setAmount(amount) {
      this.amount = amount;
    },
    async handler() {
      try {
        const { data } = await this.$http.post("wallet/deposit/pay", {
          currency: this.getBalance.active,
          amount: this.convertAmount(
            this.amount,
            this.getBalance.active,
            false
          ),
          method: this.method.name,
        });

        if (data.error) return this.$toast.error(data.error);
        const { transfer } = data.response;
        this.transfer = transfer;
        this.modalShow = true;
      } catch (error) {
        if (error.status === 307) {
          location.href = error.data.response.redirect;
        } else this.$toast.error(error?.message || "Ошибка отправки запроса");
      }
    },
    deposit() {
      if (!(this.method?.bonusBalanceAlert && this.user.balances.rub < 100))
        return this.handler();

      this.$confirm({
        title: "Вы находитесь на бонусном балансе.",
        message:
          "Если вы совершите пополнение ваш текущий баланс обнулится, вы готовы продолжить?",
        button: {
          no: "Отменить",
          yes: "Продолжить",
        },
        callback: (confirm) => {
          if (confirm) this.handler();
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.deposits {
  display: flex;
  .left {
    border-right: 1px solid #f3f5fd;
    width: 412px;
    .title {
      margin-bottom: 15px;
      color: #3a3e46;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .right {
    padding-left: 20px;
    width: calc(100% - 412px);

    .title {
      font-size: 16px;
      margin-bottom: 15px;
      font-weight: 500;
    }

    .inputs {
      border: 1px solid #f5f6fa;
      margin-bottom: 20px;
      padding: 20px;
      border-radius: 8px;
      .flex {
        display: flex;
        justify-content: space-between;
        .group {
          margin-bottom: 20px;
          width: calc(50% - 10px);
          label {
            display: block;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
            margin-bottom: 20px;
          }
          .input {
            border: 1px solid #e2e5f1;
            border-radius: 8px;
            height: 60px;
            display: flex;
            padding: 0 15px;
            align-items: center;
            img {
              margin-right: 10px;
            }
            input {
              font-weight: 500;
              font-size: 18px;
              background-color: transparent;
              width: calc(100% - 10px);
            }
          }
        }

        @media (max-width: 768px) {
          flex-wrap: wrap;
          .group {
            width: 100%;
          }
        }
      }

      button {
        background: #0085ff;
        border-radius: 8px;
        color: #ffffff;
        font-weight: 500;
        font-size: 16px;
        margin: 0 auto;
        width: 300px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 200ms ease;
        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    .left {
      width: 100%;
      border-right: none;
    }
    .right {
      width: 100%;
      padding-left: 0;
    }
  }
}
</style>
