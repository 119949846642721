<template>
  <div class="method">
    Способ оплаты:
    <img
      v-if="method.name === 'qiwi'"
      :alt="method.name"
      :class="method.name"
      src="@/assets/icons/profile/qiwi.png"
    />
    <img
      v-else-if="method.name === 'piastrix' || method.name === 'ps_wallet'"
      :alt="method.name"
      :class="method.name"
      src="@/assets/icons/profile/piastrix.png"
    />
    <img
      v-else-if="method.name === 'freekassa' || method.name === 'fk_wallet'"
      :alt="method.name"
      :class="method.name"
      src="@/assets/icons/profile/fkwallet.png"
    />
    <img
      v-else-if="method.name === 'yandex'"
      :alt="method.name"
      :class="method.name"
      src="@/assets/icons/yoomoney.png"
    />
    <img
      v-else-if="method.name === 'card'"
      :alt="method.name"
      :class="method.name"
      src="@/assets/icons/card.png"
    />

    <span>{{ method.name | translateName }}</span>
  </div>
</template>

<script>
export default {
  props: {
    method: Object,
  },
  filters: {
    translateName(value) {
      const names = {
        fk_wallet: "FKWallet",
        ps_wallet: "Piastrix",
        card: "Visa / Mastercard / Мир",
        yandex: "ЮMoney / Яндекс.Деньги",
      };
      return names[value] || value;
    },
  },
};
</script>

<style lang="scss" scoped>
.method {
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  display: flex;
  img {
    width: 20px;
    height: 20px;
    margin: 0 10px;
    object-fit: contain;
  }
  span {
    text-transform: capitalize;
  }
}
</style>
